import { gql } from "@apollo/client"
import { graphql } from "gatsby"
import { sortBy } from "lodash"
import React, { Fragment, useState } from "react"
import Breadcrumb from "src/components/Breadcrumb"
import { Button, ButtonLink } from "src/components/Button"
import Contacts from "src/components/Contacts"
import { CustomButtonBlock } from "src/components/CustomButton"
import Form from "src/components/Form"
import { Odd, Section } from "src/components/Grid"
import Image from "src/components/Image"
import Social from "src/components/Social"
import { DOMAIN_ID, LAYOUT } from "src/constants"
import { useClientQuery } from "src/helpers/apollo"
import { ActivityFields, AssociationFields, DomainThemeField, MissionFields } from "src/helpers/fragments"
import { getParam } from "src/helpers/search"
import { getActivityPath, getAssociationPath, getSearchPath } from "src/helpers/slug"
import { capitalize, formatAge, formatSchedule } from "src/helpers/text"
import { useAggregatedAddresses } from "src/helpers/useAggregatedAddresses"
import Layout from "src/layout"
import AgeIcon from "src/svg/age.svg"
import AmountIcon from "src/svg/amount.svg"
import CalendarIcon from "src/svg/calendar.svg"
import PlaceIcon from "src/svg/place.svg"
import UserIcon from "src/svg/user.svg"
import { Center, Description, Head, Header, Infos, Logo, White } from "src/templates/association"
import styled, { DefaultTheme } from "styled-components"

const Subtitle = styled.h2`
  margin-top: 30px;
`
const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  a {
    margin: 0 8px 8px 0;
  }
`
const DataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > div {
    width: 50%;
    min-width: ${(LAYOUT.width - 40) / 2}px;
  }
`
const Item = styled.div`
  display: flex;
  margin: 10px 0;
  svg {
    width: 24px;
  }
  p {
    margin: 4px 6px;
    white-space: pre-line;
  }
`
const ContactBlock = styled.div`
  margin: 20px 0;
`

const GET_ACTIVITY = gql`
  query($domainId: ID!, $id: ID!) {
    Activity(id: $id, domain: $domainId) {
      ...ActivityFields
      association {
        ...AssociationFields
      }
    }
    Domain(id: $domainId) {
      id
      mission_visible_on_frontend
      legal_notice_url
      facebook_url
      twitter_url
      instagram_url
      contact_form_url
      facebook_url
      ...DomainThemeField
    }
  }
  ${ActivityFields}
  ${AssociationFields}
  ${MissionFields}
  ${DomainThemeField}
`

interface Data {
  assolib: {
    Activity: Activity
  }
}

interface PageContext {
  id?: ID
  theme: DefaultTheme
  domain: Domain
}

interface DynamicResults {
  Activity: Activity
}

const ActivityTemplate: GatsbyPage<Data, PageContext> = ({ data: staticData, location, pageContext }) => {
  const forceDynamic = pageContext.id && getParam(location, "dynamic")

  const dynamic = useClientQuery<DynamicResults>(
    GET_ACTIVITY,
    forceDynamic
      ? {
          id: pageContext.id,
          domainId: DOMAIN_ID,
        }
      : null
  )

  const data = dynamic.data
    ? {
        Activity: dynamic.data.Activity,
        allActivities: [dynamic.data.Activity],
      }
    : staticData.assolib

  const domain = pageContext.domain

  const {
    id,
    title: activityTitle,
    addresses,
    tags,
    description_text,
    trainer_name,
    pricing_text,
    activity_schedules,
    age_min,
    age_max,
    association,
    contacts,
    customButtons,
  } = data.Activity

  const [open, setOpen] = useState(false)

  const toggleOpen = () => setOpen(!open)

  const tagName = capitalize(association.main_tag.name)
  const links = [
    [tagName, getSearchPath({ tagIds: [association.main_tag.id] })],
    [association.name, getAssociationPath(association)],
    [activityTitle, getActivityPath(data.Activity)],
  ]

  const age = formatAge(age_min, age_max)

  const addressList = addresses?.length ? addresses : association.addresses
  const aggregatedAddresses = useAggregatedAddresses(addressList)

  const contactList = sortBy(
    contacts?.length > 0 ? contacts : association.contacts?.length > 0 ? association.contacts : [],
    (contact) => (contact.is_primary ? 0 : 1)
  )

  return (
    <Layout
      title={association.name}
      domain={domain}
      description={association.description_text}
      theme={pageContext.theme}
    >
      <Header>
        {association.header_url && <Image node={association.header_image} src={association.header_url} alt="" />}
      </Header>
      <Infos>
        <Section>
          <Head>
            {association.logo_url && (
              <Logo>
                <Image node={association.logo_image} src={association.logo_url} alt="" />
              </Logo>
            )}
            <h1>{association.name}</h1>
          </Head>
          <Breadcrumb links={links} />
          <Subtitle>{activityTitle}</Subtitle>
          <Tags>
            {tags.map((tag) => (
              <ButtonLink key={tag.id} $empty to={getSearchPath({ tagIds: [tag.id] })} $size="small">
                {capitalize(tag.name)}
              </ButtonLink>
            ))}
          </Tags>
          <Description>{description_text}</Description>
          <DataContainer>
            <div>
              {trainer_name && (
                <Item>
                  <UserIcon />
                  <p>{trainer_name}</p>
                </Item>
              )}
              {pricing_text && (
                <Item>
                  <AmountIcon />
                  <p>{pricing_text}</p>
                </Item>
              )}
              {aggregatedAddresses.length > 0 && (
                <Item>
                  <PlaceIcon />
                  <p>
                    {aggregatedAddresses.map((address) => (
                      <Fragment key={address.id}>
                        {address.complements.length > 0 && <span>{address.complements.join(", ")}, </span>}
                        {address.full_address}
                        <br />
                      </Fragment>
                    ))}
                  </p>
                </Item>
              )}
            </div>
            <div>
              {activity_schedules?.length ? (
                <Item>
                  <CalendarIcon />
                  <div>
                    {activity_schedules.map((schedule) => (
                      <p key={schedule.id}>{formatSchedule(schedule)}</p>
                    ))}
                  </div>
                </Item>
              ) : null}
              {age && (
                <Item>
                  <AgeIcon />
                  <p>{age}</p>
                </Item>
              )}
            </div>
          </DataContainer>
          {contactList.length > 0 && (
            <ContactBlock>
              <Contacts contacts={contactList} />
            </ContactBlock>
          )}
          {customButtons && customButtons.length > 0 && <CustomButtonBlock buttons={customButtons} />}
        </Section>
      </Infos>
      <Social data={association} />
      <White>
        <Section>
          <h1>Je souhaite plus d’information</h1>
          <Form type="ACTIVITY_SUBSCRIPTION" activity={id} />
        </Section>
      </White>
      <Odd>
        <Center>
          <p>
            Ces informations vous paraissent incomplètes ou pas à jour ? Vous disposez d’informations complémentaires ?
            <br />
            Faites-en profiter vos voisins !
          </p>
          {open ? (
            <Form type="UPDATE_ACTIVITY_INFORMATION" activity={id} />
          ) : (
            <Button $size="small" onClick={toggleOpen}>
              Dites-le nous
            </Button>
          )}
        </Center>
      </Odd>
    </Layout>
  )
}

export default ActivityTemplate

export const query = graphql`
  query($id: ID!) {
    assolib {
      Activity(id: $id) {
        ...ActivityFields
        association {
          ...AssociationFields
        }
        contacts {
          id
          civility
          firstname
          lastname
          email
          phone
          title
          is_primary
        }
      }
    }
  }
`
